import { render } from "./hospitalCity.vue?vue&type=template&id=728823e4&scoped=true"
import script from "./hospitalCity.vue?vue&type=script&lang=js"
export * from "./hospitalCity.vue?vue&type=script&lang=js"

import "./hospitalCity.vue?vue&type=style&index=0&id=728823e4&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-728823e4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "728823e4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('728823e4', script)) {
    api.reload('728823e4', script)
  }
  
  module.hot.accept("./hospitalCity.vue?vue&type=template&id=728823e4&scoped=true", () => {
    api.rerender('728823e4', render)
  })

}

script.__file = "src/views/home/hospital/hospitalCity.vue"

export default script